<div class="container-scroller">
    <div class=" page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth">
        <div class="row flex-grow">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left p-5">
              <div class="brand-logo">
                <img src="assets/web_logo.svg">
              </div>
              <h4>Forgot Password</h4>
              <form class="pt-3" [formGroup]="login">
                <div class="form-group">
                  <label for="exampleInputPassword1">New Password</label>
                  <input type="password" class="form-control" formControlName="newPassword" id="exampleInputPassword1"
                    placeholder="Password">
                  <div class="mt-1" *ngIf="submitted&&login.controls['newPassword'].invalid &&  refresh">
                    <div class="text-small text-danger">
                      New password is required.
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Confirm Password</label>
                  <input type="password" class="form-control" formControlName="confirmPassword" id="exampleInputPassword1"
                    placeholder="Password">
                  <div *ngIf="submitted && login.controls['confirmPassword'].errors">
                    <div class="text-small text-danger"
                      *ngIf="login.controls['confirmPassword'].errors?.['required']  &&  refresh">
                      Confirm Password is required</div>
                    <div class="text-small text-danger"
                      *ngIf="login.controls['confirmPassword'].errors?.['confirmPasswordValidator'] &&  refresh">Passsword and
                      Confirm Password didn't match. </div>
                  </div>
                </div>
                <div class="mt-3">
                  <button class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                    (click)="submit()">Submit</button>
                </div>
                <div class="my-2 d-flex justify-content-between align-items-center">
                </div>
                <div class="mb-2">
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>