import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotpasswordComponent {
  login!: FormGroup;
  hide = true;
  errorMsgHide = false;
  isValid = false;
  value: any;
  public errMsg = "Please Enter Valid Email and Password";
  msg: any;
  role: any;
  public errorMsg = false;
  submitted: boolean = false;
  token: any;
  email: any;
  refresh: boolean = false;
  constructor(private router: Router, private _fb: FormBuilder, private _activatedRoute: ActivatedRoute, private appService: AppService) { }

  public error = (control: string, error: string) => {
    return this.login.controls[control].hasError(error);
  }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'] == undefined ? null : params['token'];
      this.email = JSON.parse(window.atob(this.token.split('.')[0]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
    })
    this.login = this._fb.group(
      {
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmPasswordValidator("newPassword", "confirmPassword")
      }
    );
  }

  Input(event: any) {
    if (event.target.value != null) {
      this.isValid = false;
    }
    this.value = event.target.value;
  }

  get f() { return this.login.controls; }

  submit() {
    this.submitted = true;
    this.isValid = true;
    if (this.login.invalid) {
      this.refresh = true;
      return;
    }
    else {
      var data = {
        email: this.email,
        token: this.token,
        newPassword: this.login.value.newPassword
      }
      this.appService.postresetPassword(data).subscribe((res: any) => {
        if (res.status == 'success') {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: res.message,
            confirmButtonText: 'OK',
            timer: 5000,
          }).then((res) => {
            if (res.isConfirmed) {
            }
          });
          this.ngOnInit();
          this.login.markAsTouched();
          this.login.reset();
          this.router.navigate(['forgotpassword']);
          this.refresh = false;
        }
        else if (res.status == 'error') {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: res.message,
            confirmButtonText: 'OK',
            timer: 5000
          })
        }
      });
    }
  }
}

export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    let control = formGroup.controls[controlName];
    let matchingControl = formGroup.controls[matchingControlName]
    if (
      matchingControl.errors &&
      !matchingControl.errors['confirmPasswordValidator']
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

